import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Banner from "../components/banner/banner"
import Welcome from "../components/welcome/welcome"
import Lesson from "../components/lesson/lesson"
import Theory from "../components/theory/theory"
import Lesauto from "../components/lesauto/lesauto"
import Prices from "../components/prices/prices"
import Testimonial from "../components/testimonial/testimonial"
import Contact from "../components/contact/contact"
import SiteSEO from "../components/seo"

const HomePage = ({ data }) => {
  const menu = data.allDataJson.nodes[0].menu
  const banner = data.allDataJson.nodes[0].banner
  const welcome = data.allDataJson.nodes[0].welcome
  const lesson = data.allDataJson.nodes[0].lesson
  const theory = data.allDataJson.nodes[0].theory
  const lesauto = data.allDataJson.nodes[0].lesauto
  const prices = data.allDataJson.nodes[0].prices
  const testimonial = data.allDataJson.nodes[0].testimonial
  const contact = data.allDataJson.nodes[0].contact
  const footerMenu = data.allDataJson.nodes[0].footer_menu

  return (
    <Layout menu={menu} footerMenu={footerMenu}>
      <SiteSEO title="Home" />
      <Banner
        title={banner.title}
        description={banner.description}
        subscribed={banner.subscribed_to.publicURL}
        thumbnail={banner.thumbnail.publicURL}
      />
      <Welcome title={welcome.title} description={welcome.description} />
      <Lesson
        title={lesson.title}
        description={lesson.description}
        thumbnail={lesson.thumbnail.publicURL}
      />
      <Theory
        title={theory.title}
        description={theory.description}
        thumbnail={theory.thumbnail.publicURL}
      />
      <Lesauto
        title={lesauto.title}
        description={lesauto.description}
        gallery={lesauto.gallery}
      />
      <Prices title={prices.title} packages={prices.packages} />
      <Testimonial title={testimonial.title} comments={testimonial.comments} />
      <Contact
        title={contact.title}
        description={contact.description}
        phone={contact.phone}
        email={contact.email}
      />
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query {
    allDataJson {
      nodes {
        menu {
          path
          title
          offset
        }
        banner {
          title
          description
          subscribed_to {
            publicURL
          }
          thumbnail {
            publicURL
          }
        }
        welcome {
          title
          description
        }
        lesson {
          title
          description
          thumbnail {
            publicURL
          }
        }
        theory {
          title
          description
          thumbnail {
            publicURL
          }
        }
        lesauto {
          title
          description
          gallery {
            id
            src {
              publicURL
            }
            alt
          }
        }
        prices {
          title
          packages {
            id
            title
            price
            description
          }
        }
        testimonial {
          title
          comments {
            id
            comment
            reviewer
          }
        }
        contact {
          title
          description
          phone
          email
        }
        footer_menu {
          path
          title
        }
      }
    }
  }
`
