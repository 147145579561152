import { styled } from "styletron-react"

export const Title = styled("h2", {
  color: "#02131A",
  fontSize: "32px",
  maxWidth: "830px",
  marginTop: 0,
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "30px",
  textAlign: "center",
  "@media only screen and (max-width: 1200px)": {
    fontSize: "28px",
  },
  "@media only screen and (max-width: 991px)": {
    fontSize: "26px",
  },
  "@media only screen and (max-width: 480px)": {
    fontSize: "24px",
    lineHeight: 1.5,
    marginBottom: "20px",
  },
})

export const Description = styled("div", {
  maxWidth: "830px",
  marginTop: 0,
  marginLeft: "auto",
  marginRight: "auto",
  fontSize: "16px",
  lineHeight: 2,
  color: "rgba(2, 19, 26, 0.8)",
  "@media only screen and (max-width: 1200px)": {
    fontSize: "15px",
  },
})

export const Image = styled("img", {
  maxWidth: "100%",
  "@media only screen and (max-width: 1200px)": {
    maxWidth: "86%",
    marginLeft: "7%",
  },
  "@media only screen and (min-width: 991px)": {
    marginTop: "-60px",
  },
  "@media only screen and (max-width: 480px)": {
    maxWidth: "100%",
    marginLeft: "0",
  },
})
