import { styled } from "styletron-react"

const Wrapper = styled("div", {
  paddingTop: "80px",
  paddingBottom: "60px",
  position: "relative",
  "@media only screen and (max-width: 767px)": {
    paddingTop: "60px",
    paddingBottom: "50px",
  },
  "@media only screen and (max-width: 480px)": {
    paddingTop: "40px",
    paddingBottom: "40px",
  },
})

export const Content = styled("div", {
  maxWidth: "500px",
  width: "100%",
  "@media only screen and (max-width: 1200px)": {
    maxWidth: "400px",
  },
  "@media only screen and (max-width: 767px)": {
    maxWidth: "100%",
  },
})

export const Title = styled("h2", {
  color: "#02131A",
  fontSize: "34px",
  lineHeight: 1.4,
  marginTop: 0,
  marginBottom: "30px",
  "@media only screen and (max-width: 1200px)": {
    fontSize: "28px",
  },
  "@media only screen and (max-width: 991px)": {
    fontSize: "26px",
  },
  "@media only screen and (max-width: 480px)": {
    fontSize: "24px",
    lineHeight: 1.5,
    marginBottom: "20px",
  },
})

export const Description = styled("div", {
  marginTop: 0,
  fontSize: "16px",
  lineHeight: 2,
  color: "rgba(52, 61, 72, 0.8)",
  "@media only screen and (max-width: 1200px)": {
    fontSize: "15px",
  },
})

export const Image = styled("img", {
  maxWidth: "52%",
  position: "absolute",
  "@media only screen and (max-width: 767px)": {
    maxWidth: "80%",
    position: "unset",
    marginBottom: "50px",
  },
  "@media only screen and (max-width: 480px)": {
    maxWidth: "90%",
    marginBottom: "40px",
  },
})

export default Wrapper
