import React from "react"
import { useStyletron } from "styletron-react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import Container from "../container/container"
import Carousel, { Slide } from "../carousel/carousel"
import { Title, CommentWrapper, Comment, Reviewer } from "./testimonial.styled"

const Testimonial = ({ title, comments }) => {
  const [css] = useStyletron()

  return (
    <Container
      className={css({
        paddingTop: "100px",
        paddingBottom: "120px",
        "@media only screen and (max-width: 767px)": {
          paddingTop: "81px",
          paddingBottom: "100px",
        },
        "@media only screen and (max-width: 480px)": {
          paddingTop: "80px",
          paddingBottom: "70px",
        },
      })}
    >
      <Title>{title}</Title>
      <Carousel
        controls={true}
        numberOfBullets={3}
        options={{
          type: "carousel",
          gap: 30,
          perView: 2,
          startAt: 0,
          breakpoints: {
            667: {
              perView: 1,
            },
          },
        }}
        prevButton={<IoIosArrowBack />}
        nextButton={<IoIosArrowForward />}
        carouselSelector="testimonial-gallery"
      >
        {comments.map(item => (
          <Slide key={item.id}>
            <CommentWrapper>
              <Comment>{item.comment}</Comment>
              <Reviewer>{item.reviewer}</Reviewer>
            </CommentWrapper>
          </Slide>
        ))}
      </Carousel>
    </Container>
  )
}

export default Testimonial
