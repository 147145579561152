import React from "react"
import { useStyletron } from "styletron-react"
import Container from "../container/container"
import { Title, Description, Image } from "./theory.styled"

const Theory = ({ title, description, thumbnail }) => {
  const [css] = useStyletron()

  return (
    <Container
      id="theorie"
      className={css({
        maxWidth: "1100px",
        paddingTop: "60px",
        paddingBottom: "60px",
        "@media only screen and (max-width: 767px)": {
          paddingTop: "50px",
          paddingBottom: "50px",
        },
        "@media only screen and (max-width: 480px)": {
          paddingTop: "40px",
          paddingBottom: "40px",
        },
      })}
    >
      <Title>{title}</Title>
      <Description
        dangerouslySetInnerHTML={{ __html: description }}
      ></Description>
      <Image src={thumbnail} alt={title} />
    </Container>
  )
}

export default Theory
