import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import Container from "../container/container"
import Wrapper, {
  Title,
  Packages,
  ItemButtonContent,
  ItemDescriptionContent,
} from "./prices.styled"

const Prices = ({ title, packages }) => (
  <Wrapper id="tarieven">
    <Container>
      <Title>{title}</Title>
      <Packages>
        <Accordion preExpanded={packages[1].id}>
          {packages.map(item => (
            <AccordionItem key={`accordion-item--key${item.id}`} id={item.id}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <ItemButtonContent>
                    <span>{item.title}</span>
                    <span>{item.price}</span>
                  </ItemButtonContent>
                </AccordionItemButton>
              </AccordionItemHeading>
              {item.description && (
                <AccordionItemPanel>
                  <ItemDescriptionContent>
                    <strong>Inclusief:</strong>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></div>
                  </ItemDescriptionContent>
                </AccordionItemPanel>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Packages>
    </Container>
  </Wrapper>
)

export default Prices
