import { styled } from "styletron-react"

export const Content = styled("div", {
  width: "48%",
  paddingRight: "90px",
  "@media only screen and (max-width: 1200px)": {
    width: "52%",
    paddingRight: "60px",
  },
  "@media only screen and (max-width: 767px)": {
    width: "100%",
    paddingRight: "0",
  },
})

export const Title = styled("h2", {
  color: "#02131A",
  fontSize: "34px",
  lineHeight: 1.4,
  marginTop: 0,
  marginBottom: "30px",
  "@media only screen and (max-width: 1200px)": {
    fontSize: "28px",
  },
  "@media only screen and (max-width: 991px)": {
    fontSize: "26px",
  },
  "@media only screen and (max-width: 480px)": {
    fontSize: "24px",
    lineHeight: 1.5,
    marginBottom: "20px",
  },
})

export const Description = styled("div", {
  marginTop: 0,
  fontSize: "16px",
  lineHeight: 2,
  color: "rgba(52, 61, 72, 0.8)",
  "@media only screen and (max-width: 1200px)": {
    fontSize: "15px",
  },
})

export const CarouselWrapper = styled("div", {
  width: "52%",
  overflow: "hidden",
  "@media only screen and (max-width: 1200px)": {
    width: "48%",
  },
  "@media only screen and (max-width: 767px)": {
    width: "90%",
    paddingTop: "30px",
  },
  "@media only screen and (max-width: 480px)": {
    width: "100%",
  },
})

export const Image = styled("img", {
  borderRadius: "5px",
})
