import React from "react"
import Wrapper, { Title, Description } from "./welcome.styled"

const Welcome = ({ title, description }) => (
  <Wrapper>
    <Title>{title}</Title>
    <Description
      dangerouslySetInnerHTML={{ __html: description }}
    ></Description>
  </Wrapper>
)

export default Welcome
