import { styled } from "styletron-react"

export const Title = styled("h2", {
  color: "#02131A",
  fontSize: "32px",
  maxWidth: "830px",
  marginTop: 0,
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "30px",
  textAlign: "center",
  "@media only screen and (max-width: 1200px)": {
    fontSize: "28px",
  },
  "@media only screen and (max-width: 991px)": {
    fontSize: "26px",
  },
  "@media only screen and (max-width: 480px)": {
    fontSize: "24px",
    lineHeight: 1.4,
  },
})

export const CommentWrapper = styled("div", {
  paddingTop: "40px",
  paddingLeft: "40px",
  paddingRight: "40px",
  paddingBottom: "50px",
  borderRadius: "10px",
  minHeight: "280px",
  backgroundColor: "#F8F9F9",
  "@media only screen and (max-width: 767px)": {
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "40px",
  },
})

export const Comment = styled("p", {
  color: "rgba(2, 19, 26, 0.8)",
  fontSize: "17px",
  lineHeight: 2,
  marginTop: 0,
  "@media only screen and (max-width: 1200px)": {
    fontSize: "15px",
  },
})

export const Reviewer = styled("h5", {
  color: "#343D48",
  fontSize: "16px",
  fontWeight: 600,
  marginBottom: 0,
  "@media only screen and (max-width: 1200px)": {
    fontSize: "15px",
  },
})
