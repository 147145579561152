import React from "react"
import { useStyletron } from "styletron-react"
import Container from "../container/container"
import Wrapper, { Content, Title, Description, Image } from "./lesson.styled"

const Lesson = ({ title, description, thumbnail }) => {
  const [css] = useStyletron()

  return (
    <Wrapper id="rijlessen">
      <Image src={thumbnail} alt={title} />
      <Container
        className={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        })}
      >
        <Content>
          <Title>{title}</Title>
          <Description
            dangerouslySetInnerHTML={{ __html: description }}
          ></Description>
        </Content>
      </Container>
    </Wrapper>
  )
}

export default Lesson
