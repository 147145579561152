import { styled } from "styletron-react"

export const Content = styled("div", {
  width: "51%",
  paddingRight: "90px",
  "@media only screen and (max-width: 1200px)": {
    paddingRight: "30px",
  },
  "@media only screen and (max-width: 480px)": {
    width: "100%",
    paddingRight: 0,
  },
})

export const Title = styled("h2", {
  color: "#02131A",
  fontSize: "30px",
  fontWeight: 500,
  marginTop: 0,
  "@media only screen and (max-width: 1200px)": {
    fontSize: "28px",
  },
  "@media only screen and (max-width: 991px)": {
    fontSize: "26px",
  },
  "@media only screen and (max-width: 480px)": {
    fontSize: "24px",
    lineHeight: 1.3,
    fontWeight: 600,
  },
})

export const Description = styled("div", {
  color: "rgba(2, 19, 26, 0.75)",
  fontSize: "16px",
  lineHeight: 2,
  marginTop: 0,
  marginBottom: "40px",
  "@media only screen and (max-width: 1200px)": {
    fontSize: "15px",
  },
})

export const Info = styled("div", {
  color: "rgba(2, 19, 26, 0.8)",
  fontSize: "26px",
  display: "flex",
  alignItems: "center",
  marginTop: "20px",
  "@media only screen and (max-width: 1200px)": {
    fontSize: "20px",
  },
  "@media only screen and (max-width: 767px)": {
    width: "100%",
    fontSize: "18px",
  },
})

export const Form = styled("form", {
  width: "49%",
  "@media only screen and (max-width: 767px)": {
    width: "100%",
    marginTop: "40px",
  },
})

export const FieldWrapper = styled("div", {
  marginBottom: "25px",
})

export const Label = styled("label", {
  display: "flex",
  color: "#02131A",
  fontSize: "14px",
  lineHeight: 1,
  marginBottom: "12px",
})

export const Input = styled("input", {
  minHeight: "50px",
  borderRadius: "3px",
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor: "#F8F9F9",
  color: "#248DF6",
  fontSize: "15px",
  lineHeight: 1,
  width: "100%",
  display: "flex",
  alignItems: "center",
  paddingLeft: "24px",
  paddingRight: "24px",
  backgroundColor: "#F8F9F9",
  transition: "border-color 0.3s ease",
  ":focus": {
    outline: 0,
    borderColor: "#248DF6",
  },
})

export const Textarea = styled("textarea", {
  minHeight: "130px",
  borderRadius: "3px",
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor: "#F8F9F9",
  color: "#248DF6",
  fontSize: "15px",
  lineHeight: 1,
  width: "100%",
  display: "flex",
  alignItems: "center",
  paddingTop: "15px",
  paddingLeft: "24px",
  paddingRight: "24px",
  backgroundColor: "#F8F9F9",
  resize: "none",
  transition: "border-color 0.3s ease",
  ":focus": {
    outline: 0,
    borderColor: "#248DF6",
  },
})

export const Button = styled("button", {
  color: "#ffffff",
  minWidth: "130px",
  height: "45px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "14px",
  fontWeight: "700",
  borderWidth: 0,
  borderRadius: "5px",
  cursor: "pointer",
  backgroundColor: "#3E9AF5",
  marginTop: "30px",
  ":focus": {
    outline: 0,
  },
})
