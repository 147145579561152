import React from "react"
import { useStyletron } from "styletron-react"
import Container from "../container/container"
import Wrapper, {
  Title,
  Description,
  Subscribed,
  SubscribedImage,
  Thumbnail,
} from "./banner.style"

const Banner = ({ title, description, subscribed, thumbnail }) => {
  const [css] = useStyletron()

  return (
    <Wrapper id="banner">
      <Container
        className={css({
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "1fr 1fr",
          gridTemplateAreas: `
          "title  image"
          "description  image"
          "subscribed  image"`,
          "@media only screen and (max-width: 767px)": {
            gridTemplateAreas: `
          "title  title"
          "description  description"
          "subscribed  subscribed"
          "image  image"`,
            textAlign: "center",
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingTop: "70px",
          },
          "@media only screen and (max-width: 480px)": {
            textAlign: "left",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "80px",
          },
        })}
      >
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Subscribed>
          Ingeschreven bij: <SubscribedImage src={subscribed} alt="Subs" />
        </Subscribed>
        <Thumbnail>
          <img src={thumbnail} alt="banner" />
        </Thumbnail>
      </Container>
    </Wrapper>
  )
}

export default Banner
