import { styled } from "styletron-react"

const Wrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  minHeight: "100vh",
  backgroundColor: "#248DF6",
})

export const Title = styled("h1", {
  gridArea: "title",
  color: "#ffffff",
  fontSize: "50px",
  lineHeight: 1.4,
  marginTop: 0,
  marginBottom: 0,
  alignSelf: "flex-end",
  "@media only screen and (max-width: 1200px)": {
    fontSize: "42px",
  },
  "@media only screen and (max-width: 767px)": {
    fontSize: "36px",
  },
  "@media only screen and (max-width: 480px)": {
    fontSize: "30px",
  },
})

export const Description = styled("p", {
  gridArea: "description",
  color: "#ffffff",
  fontSize: "18px",
  lineHeight: 2,
  alignSelf: "flex-start",
  marginBottom: 0,
  "@media only screen and (max-width: 1200px)": {
    fontSize: "16px",
  },
})

export const Subscribed = styled("div", {
  gridArea: "subscribed",
  color: "#ffffff",
  fontSize: "16px",
  alignSelf: "flex-start",
  display: "flex",
  alignItems: "center",
  "@media only screen and (max-width: 767px)": {
    marginTop: "30px",
    justifyContent: "center",
  },
  "@media only screen and (max-width: 480px)": {
    justifyContent: "flex-start",
  },
})

export const SubscribedImage = styled("img", {
  maxWidth: "210px",
  height: "auto",
  top: "-2px",
  position: "relative",
  left: "8px",
  "@media only screen and (max-width: 767px)": {
    maxWidth: "180px",
  },
  "@media only screen and (max-width: 480px)": {
    maxWidth: "160px",
  },
})

export const Thumbnail = styled("div", {
  gridArea: "image",
  "@media only screen and (max-width: 767px)": {
    maxWidth: "420px",
    width: "100%",
    margin: "0 auto",
  },
})

export default Wrapper
