import React, { useEffect } from "react"
import Glide from "@glidejs/glide"
import "@glidejs/glide/dist/css/glide.core.min.css"

const Carousel = ({
  className,
  children,
  options,
  controls,
  prevButton,
  nextButton,
  bullets,
  thumbs,
  numberOfBullets,
  carouselSelector,
}) => {
  const addAllClasses = ["glide"]
  if (className) {
    addAllClasses.push(className)
  }

  const totalBullets = []
  for (let i = 0; i < numberOfBullets; i++) {
    totalBullets.push(i)
  }

  useEffect(() => {
    const glide = new Glide(
      carouselSelector ? `#${carouselSelector}` : "#glide",
      {
        ...options,
      }
    )
    glide.mount()
  })

  return (
    <div className={addAllClasses.join(" ")} id={carouselSelector || "glide"}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">{children}</ul>
      </div>

      {controls && (
        <div className="glide__controls" data-glide-el="controls">
          <div className="glide__prev--area" data-glide-dir="<">
            {prevButton ? prevButton : <button>Prev</button>}
          </div>
          <div className="glide__next--area" data-glide-dir=">">
            {nextButton ? nextButton : <button>Next</button>}
          </div>
        </div>
      )}

      {bullets && (
        <div className="glide__bullets" data-glide-el="controls[nav]">
          {totalBullets.map(index => (
            <button
              key={index}
              aria-label="glide bullet"
              className="glide__bullet"
              data-glide-dir={`=${index}`}
            />
          ))}
        </div>
      )}

      {bullets !== true && thumbs && thumbs.length > 0 && (
        <div className="glide__bullets" data-glide-el="controls[nav]">
          {thumbs.map((item, index) => (
            <div
              key={index}
              className="glide__thumb"
              data-glide-dir={`=${index}`}
            >
              <img src={item.url} alt="gallery" />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const Slide = ({ children }) => {
  return <li className="glide__slide">{children}</li>
}

Carousel.defaultProps = {
  controls: true,
  bullets: false,
}

export { Slide }
export default Carousel
