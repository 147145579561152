import { styled } from "styletron-react"

const Wrapper = styled("div", {
  marginTop: "60px",
  paddingTop: "81px",
  paddingBottom: "120px",
  backgroundColor: "#F6F8F9",
  "@media only screen and (max-width: 991px)": {
    paddingBottom: "100px",
  },
  "@media only screen and (max-width: 480px)": {
    marginTop: "40px",
    paddingTop: "80px",
    paddingBottom: "80px",
  },
})

export const Title = styled("h2", {
  color: "#02131A",
  fontSize: "32px",
  maxWidth: "830px",
  marginTop: 0,
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "50px",
  textAlign: "center",
  "@media only screen and (max-width: 1200px)": {
    fontSize: "28px",
  },
  "@media only screen and (max-width: 991px)": {
    fontSize: "26px",
  },
  "@media only screen and (max-width: 480px)": {
    fontSize: "24px",
    lineHeight: 1.5,
  },
})

export const Packages = styled("div", {
  paddingTop: "30px",
  paddingLeft: "70px",
  paddingRight: "70px",
  paddingBottom: "40px",
  borderRadius: "10px",
  backgroundColor: "#ffffff",
  boxShadow: "0 10px 35px rgba(16, 66, 97, 0.06)",
  "@media only screen and (max-width: 991px)": {
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  "@media only screen and (max-width: 767px)": {
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
})

export const ItemButtonContent = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  color: "#02131A",
  fontSize: "16px",
  "@media only screen and (max-width: 1200px)": {
    fontSize: "15px",
  },
})

export const ItemDescriptionContent = styled("div", {
  display: "flex",
})

export default Wrapper
