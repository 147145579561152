import React from "react"
import { useStyletron } from "styletron-react"
import { FaPhone } from "react-icons/fa"
import { IoLogoWhatsapp, IoIosMail } from "react-icons/io"
import Container from "../container/container"
import {
  Content,
  Title,
  Description,
  Info,
  Form,
  FieldWrapper,
  Label,
  Input,
  Textarea,
  Button,
} from "./contact.styled"

const Contact = ({ title, description, phone, email }) => {
  const [css] = useStyletron()

  return (
    <Container
      id="contact"
      className={css({
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "65px",
        paddingLeft: "80px",
        paddingRight: "80px",
        paddingBottom: "97px",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        boxShadow: "0 12px 35px rgba(16, 66, 97, 0.06)",
        "@media only screen and (max-width: 991px)": {
          paddingLeft: "40px",
          paddingRight: "40px",
        },
        "@media only screen and (max-width: 767px)": {
          flexDirection: "column",
          paddingBottom: "70px",
        },
        "@media only screen and (max-width: 480px)": {
          paddingLeft: "30px",
          paddingRight: "30px",
        },
      })}
    >
      <Content>
        <Title>{title}</Title>
        <Description
          dangerouslySetInnerHTML={{ __html: description }}
        ></Description>
        <Info>
          <FaPhone color="#D8DBDC" style={{ marginRight: "20px" }} />
          <IoLogoWhatsapp color="#D8DBDC" style={{ marginRight: "20px" }} />
          {phone}
        </Info>
        <Info
          className={css({
            marginLeft: "42px",
            "@media only screen and (max-width: 767px)": { marginLeft: "34px" },
          })}
        >
          <IoIosMail
            color="#D8DBDC"
            size="1.2em"
            style={{ marginRight: "20px" }}
          />{" "}
          {email}
        </Info>
      </Content>
      <Form action="https://formspree.io/xknrzeag" method="POST">
        <FieldWrapper>
          <Label htmlFor="fullName">Je volledige naam</Label>
          <Input type="text" id="fullName" name="fullName" />
        </FieldWrapper>
        <FieldWrapper>
          <Label htmlFor="emailAddress">Je e-mail adres</Label>
          <Input type="email" id="emailAddress" name="_replyto" />
        </FieldWrapper>
        <FieldWrapper>
          <Label htmlFor="telephoneNumber">Je telefoonnummer</Label>
          <Input type="number" id="telephoneNumber" name="telephoneNumber" />
        </FieldWrapper>
        <FieldWrapper>
          <Label htmlFor="message">Je bericht</Label>
          <Textarea name="message" id="message"></Textarea>
        </FieldWrapper>
        <input type="text" name="_gotcha" style={{ display: "none" }} />
        <Button type="submti">Versturen</Button>
      </Form>
    </Container>
  )
}

export default Contact
