import React from "react"
import { useStyletron } from "styletron-react"
import Container from "../container/container"
import Carousel, { Slide } from "../carousel/carousel"
import {
  Content,
  Title,
  Description,
  CarouselWrapper,
  Image,
} from "./lesauto.styled"

const Lesauto = ({ title, description, gallery }) => {
  const [css] = useStyletron()

  return (
    <Container
      id="lesauto"
      className={css({
        display: "flex",
        alignItems: "center",
        paddingTop: "60px",
        paddingBottom: "60px",
        "@media only screen and (max-width: 767px)": {
          flexDirection: "column",
          paddingTop: "50px",
          paddingBottom: "50px",
        },
        "@media only screen and (max-width: 480px)": {
          paddingTop: "40px",
          paddingBottom: "40px",
        },
      })}
    >
      <Content>
        <Title>{title}</Title>
        <Description
          dangerouslySetInnerHTML={{ __html: description }}
        ></Description>
      </Content>
      <CarouselWrapper>
        <Carousel
          controls={false}
          bullets={true}
          numberOfBullets={4}
          options={{ type: "carousel", gap: 0 }}
          carouselSelector="lesauto-gallery"
        >
          {gallery.map(item => (
            <Slide key={item.id}>
              <Image src={item.src.publicURL} alt={item.alt} />
            </Slide>
          ))}
        </Carousel>
      </CarouselWrapper>
    </Container>
  )
}

export default Lesauto
